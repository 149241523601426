import React from 'react';

export default function TermsAccordionTitle1() {
    return (
        <div >
            <div className="langCng" lang="en">
                RESPONSIBILITY
            </div>

            <div className="langCng" lang="es">
                RESPONSABILIDAD
            </div>

            <div className="langCng" lang="ru">
                ОБЯЗАННОСТЬ
            </div>

            <div className="langCng" lang="fr">
                RESPONSABILITÉ
            </div>

            <div className="langCng" lang="de">
                VERANTWORTUNG
            </div>

            <div className="langCng" lang="it">
                RESPONSABILITÀ
            </div>

            <div className="langCng" lang="hi">
                ज़िम्मेदारी
            </div>

            <div className="langCng" lang="id">
                TANGGUNG JAWAB
            </div>

            <div className="langCng" lang="pt">
                RESPONSABILIDADE
            </div>

            <div className="langCng" lang="fi">
                VASTUU
            </div>

            <div className="langCng" lang="tr">
                SORUMLULUK
            </div>          

            <div className="langCng" lang="ms">
                TANGGUNGJAWAB
            </div>

            <div className="langCng" lang="ta">
                பொறுப்பு
            </div>     

            <div className="langCng" lang="ar">
                مسؤولية
            </div>

            <div className="langCng" lang="ja">
                責任
            </div>

            <div className="langCng" lang="zh">
                责任
            </div>

            <div className="langCng" lang="ko">
                책임
            </div>
        </div>
    );
  }