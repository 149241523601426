import React from 'react';

export default function TermsAccordionContant1() {
    return (
        <div >
            <div className="langCng" lang="en">
                The user shall use this service at the user's responsibility.<br/>
                The user shall bear all responsibility for all actions taken in this service and the results thereof.
            </div>

            <div className="langCng" lang="es">
                El usuario utilizará este servicio bajo su responsabilidad.<br/>
                El usuario asumirá toda la responsabilidad por todas las acciones realizadas en este servicio y los resultados de las mismas.
            </div>

            <div className="langCng" lang="ru">
                Пользователь должен использовать эту услугу под свою ответственность.<br/>
                Пользователь несет всю ответственность за все действия,<br/>
                совершенные в этом сервисе, и их результаты.
            </div>

            <div className="langCng" lang="fr">
                L'utilisateur utilisera ce service sous sa responsabilité.<br/>
                L'utilisateur assume l'entière responsabilité de toutes les actions entreprises dans ce service et de leurs résultats.
            </div>

            <div className="langCng" lang="de">
                Die Nutzung dieses Dienstes erfolgt auf eigene Verantwortung des Nutzers.<br/>
                Der Benutzer trägt die volle Verantwortung für alle in diesem Dienst vorgenommenen Handlungen und deren Ergebnisse.
            </div>

            <div className="langCng" lang="it">
                L'utente utilizzerà questo servizio sotto la responsabilità dell'utente.<br/>
                L'utente si assume ogni responsabilità per tutte le azioni intraprese in questo servizio e i risultati dello stesso.
            </div>

            <div className="langCng" lang="hi">
                उपयोगकर्ता इस सेवा का उपयोग उपयोगकर्ता की जिम्मेदारी पर करेगा।<br/>
                उपयोगकर्ता इस सेवा में की गई सभी कार्रवाइयों और उसके परिणामों के लिए सभी जिम्मेदारी वहन करेगा।
            </div>

            <div className="langCng" lang="id">
                Pengguna harus menggunakan layanan ini atas tanggung jawab pengguna.<br/>
                Pengguna harus menanggung semua tanggung jawab untuk semua tindakan yang diambil dalam layanan ini dan hasilnya.
            </div>

            <div className="langCng" lang="pt">
                O usuário deverá utilizar este serviço sob sua responsabilidade.<br/>
                O usuário terá toda a responsabilidade por todas as ações realizadas neste serviço e seus resultados.
            </div>

            <div className="langCng" lang="fi">
                Käyttäjä käyttää tätä palvelua käyttäjän vastuulla.<br/>
                Käyttäjä on vastuussa kaikista tässä palvelussa tehdyistä toimista ja niiden tuloksista.
            </div>

            <div className="langCng" lang="tr">
                Kullanıcı, sorumluluğu kendisine ait olmak üzere bu hizmeti kullanacaktır.,<br/>
                Kullanıcı, bu hizmette yapılan tüm eylemlerden ve bunların sonuçlarından sorumlu olacaktır.
            </div>          

            <div className="langCng" lang="ms">
                Pengguna hendaklah menggunakan perkhidmatan ini di atas tanggungjawab pengguna.<br/>
                Pengguna hendaklah memikul semua tanggungjawab untuk semua tindakan yang diambil dalam perkhidmatan ini dan keputusannya.
            </div>

            <div className="langCng" lang="ta">
                பயனர் இந்த சேவையை பயனரின் பொறுப்பில் பயன்படுத்த வேண்டும்.<br/>
                இந்தச் சேவையில் எடுக்கப்பட்ட அனைத்து செயல்களுக்கும் அதன் முடிவுகளுக்கும் பயனர் அனைத்துப் பொறுப்பையும் ஏற்க வேண்டும்.
            </div>     

            <div className="langCng" lang="ar">
                يجب على المستخدم استخدام هذه الخدمة على مسؤولية المستخدم.<br/>
                يتحمل المستخدم كامل المسؤولية عن جميع الإجراءات المتخذة في هذه الخدمة ونتائجها.
            </div>

            <div className="langCng" lang="ja">
                利用者は、利用者の責任において本サービスを利用するものとする。<br/>
                本サービスにおいて行った一切の行為および、その結果についてはユーザーが一切の責任を負うものとします。
            </div>

            <div className="langCng" lang="zh">
                用户应自行负责使用本服务。<br/>
                用户应对在本服务中采取的一切行为及其结果承担全部责任。
            </div>

            <div className="langCng" lang="ko">
                이용자는 이용자의 책임하에 본 서비스를 이용하여야 합니다.<br/>
                본 서비스에서 취한 모든 행위 및 그 결과에 대한 모든 책임은 이용자에게 있습니다.
            </div>
        </div>
    );
  }